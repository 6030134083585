@charset "UTF-8";

// ========================================
// モジュール設定
// ========================================

// デザインスタイル
// ----------------------------------------
.sec {
    .inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
    }
    .inner-wide {
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;
    }
    .inner-narrow {
        max-width: 640px;
        margin-right: auto;
        margin-left: auto;
    }
    p {
        text-align: justify;
    }
    p + p {
        margin-top: 20px;
    }
    h1 + p, h2 + p {
        margin-top: 30px;
    }
    h3 + p, h4 + p {
        margin-top: 20px;
    }
    figure + p, p + figure {
        margin-top: calc(30px + 30*(100vw - 320px)/1280);
    }
}

.sec-bg {
    background-color: white;
    padding: calc(80px + 40*(100vw - 320px)/1280) 5%;
}




// block
.block-full {
    & .pic {
        position: relative;
        width: 100%;
        @include media(md) {
            min-height: calc(320px + 360*(100vw - 320px)/1280);
            max-height: calc(320px + 360*(100vw - 320px)/1280);
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
}

.block-half {
    display: block;
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    &.reverse {
        @include media(md) {
            flex-flow: row-reverse nowrap;
        }
    }
    & > .pic {
        width: 100%;
        min-width:0;
        @include media(md) {
            width: 50%;
        }
    }
    & > .box {
        padding: calc(20px + 20*(100vw - 320px)/1280) 5%;
        min-width:0;
        @include media(md) {
            width: 50%;
        }
    }
}

.block-half-nr {
    display: block;
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: top;
        justify-content: space-between;
    }
    &.reverse {
        @include media(md) {
            flex-flow: row-reverse nowrap;
        }
    }
    & > .pic {
        min-width:0;
        width: 100%;
        @include media(md) {
            width: 47.5%;
            flex: 0 0 47.5%;
        }
    }
    & > .detail {
        margin-top: 30px;
        min-width:0;
        width: 100%;
        @include media(md) {
            margin: 0;
            width: 47.5%;
            flex: 0 0 47.5%;
        }
    }
}


.block-memo {
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
}


.block-basic {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.block-bg {
    background-color: $thin;
    padding: calc(20px + 20*(100vw - 320px)/1280);
    &.white {
        background-color: white;
    }
    &-md {
        padding: calc(20px + 40*(100vw - 320px)/1280);
    }
    &-lg {
        padding: calc(30px + 70*(100vw - 320px)/1280) calc(20px + 40*(100vw - 320px)/1280);
    }
}


.block-border {
    border: 1px solid $pale;
    padding: calc(10px + 30*(100vw - 320px)/1280);
}



.block-2c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -60px 0 0 -40px;
        }
    }
    .col {
        padding: 40px 0 0 0;
        @include media(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 60px 0 0 40px;
        }
    }
}

.block-3c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -60px 0 0 -20px;
        }
    }
    .col {
        padding: 40px 0 0 0;
        @include media(sm) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding: 60px 0 0 20px;
        }
    }
}

.block-4c {
    .row {
        margin: -40px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -40px 0 0 -40px;
        }
    }
    .col {
        padding: 40px 0 0;
        @include media(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 40px 0 0 40px;
        }
        @include media(md) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}


.grid {
    margin: 0 0 -30px;
    @include media(sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 -30px -30px;
    }
    &.reverse {
        flex-direction: row-reverse;
    }
    &.sm-reverse {
        @include media(sm) {
            flex-direction: row-reverse;
        }
    }
    &.mt-m {
        margin-bottom: -60px;
    }
    &.nowrap {
        flex-wrap: nowrap;
    }
    &.jc-center {
        justify-content: center;
    }
    &.jc-end {
        justify-content: flex-end;
    }
    &.jc-between {
        justify-content: space-between;
    }
    &.jc-around {
        justify-content: space-around;
    }
    &.ai-start {
        align-items: flex-start;
    }
    &.ai-end {
        align-items: flex-end;
    }
    &.ai-center {
        align-items: center;
    }
    &.sm-4 {
        .col {
            @include media(sm) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.sm-3 {
        .col {
            @include media(sm) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.sm-2 {
        .col {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.sm-2 {
        .col {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.md-4 {
        .col {
            @include media(md) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.md-3 {
        .col {
            @include media(md) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.md-2 {
        .col {
            @include media(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.md-2 {
        .col {
            @include media(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.lg-4 {
        .col {
            @include media(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    &.lg-3 {
        .col {
            @include media(lg) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
    }
    &.lg-2 {
        .col {
            @include media(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.lg-2 {
        .col {
            @include media(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    &.mt-m {
        .col {
            padding-bottom: 60px;
        }
    }
    .col {
        padding: 0 0 30px;
        max-width: 100%;
        @include media(sm) {
            display: block;
            padding: 0 0 30px calc(20px + 10*(100vw - 320px)/1280);
            min-width: 0;
        }
        &.sm-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.sm-1-3 {
            @include media(sm) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.sm-2-3 {
            @include media(sm) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.sm-1-4 {
            @include media(sm) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.sm-3-4 {
            @include media(sm) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        &.md-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.md-1-3 {
            @include media(md) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.md-2-3 {
            @include media(md) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.md-1-4 {
            @include media(md) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.md-3-4 {
            @include media(md) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        &.lg-1-2 {
            @include media(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        &.lg-1-3 {
            @include media(lg) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
        }
        &.lg-2-3 {
            @include media(lg) {
                flex: 0 0 66.6666%;
                max-width: 66.6666%;
            }
        }
        &.lg-1-4 {
            @include media(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        &.lg-3-4 {
            @include media(lg) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
    }
}




// btn
.btn {
    &.white {
        a {
            border-bottom: 1px solid white;
            color: white;
            &:after {
                border-right: 1px solid white;
            }
        }
    }
    a {
        display: inline-block;
        padding: 10px 20px 20px 0;
        min-width: 240px;
        position: relative;
        line-height: 1.3;
        transition: min-width .3s;
        &:hover {
            min-width: 260px;
            &:before {
                background-color: darken($link, 20%);
            }
            &:after {
                border-color: darken($link, 20%);
            }
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
            transition: background-color .3s;
            background-color: $link;
        }
        &:after {
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            top: 50%;
            right: 10px;
            transform: rotate(45deg) translate(-6px, 0);
            border-top: 1px solid $link;
            border-right: 1px solid $link;
            line-height: 1.0;
            content: '';
            transition: border-color .3s;
        }
    }
}




// title
.wf-active {
    .title-main {
        font-family: $font-min;
        span {
            font-family: $font-en;
        }
    }
}

.title-main {
    font-family: $font-load;
    font-size: calc(16px + 4*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 700;
    @include media(xs) {
        line-height: 1.6;
    }
    span {
        font-family: $font-load;
        display: block;
        font-size: calc(40px + 40*(100vw - 320px)/1280);
        line-height: 1.1;
        color: $key;
        font-weight: 400;
    }
}













// table

.table-wrap {
    @include media(xs) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    th, td {
        @include media(xs) {
            white-space: nowrap;
        }
    }
}
.table-simple {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $pale;
    tr {
        border-bottom: 1px solid $pale;
    }
    th {
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(15px + 15*(100vw - 320px)/1280);
        font-weight: 400;
        line-height: 1.6;
    }
    td {
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(15px + 15*(100vw - 320px)/1280);
        z-index: -1;
        line-height: 1.6;
    }
    thead {
    }
    tbody {
        th {
        }
    }
    &.xs-row {
        tr {
            @include media(xs) {
                display: block;
            }
        }
        th {
            @include media(xs) {
                display: block;
            }
        }
        td {
            @include media(xs) {
                display: block;
                padding: 10px 10px 20px;
            }
        }
        thead {
            @include media(xs) {
                display: block;
            }
        }
        tbody {
            @include media(xs) {
                display: block;
            }
        }
    }
    &.nowrap {
        th, td {
            white-space: nowrap;
        }
    }
    &.th-nowrap {
        th {
            white-space: nowrap;
        }
    }
    &.t-border {
        th, td {
            border: 1px solid $pale;
        }
    }
    &.bg-white {
        th, td {
            background-color: white;
        }
    }
    &.bg-none {
        thead {
                background-color: transparent;
        }
        th, td {
            background-color: transparent;
        }
    }
    &.t-noborder {
        border: none;
        th, td {
            border: none;
            padding: 10px;
        }
    }
    &.th-top tbody th,
    &.td-top tbody td {
        vertical-align: top;
    }
    &.th-middle tbody th,
    &.td-middle tbody td {
        vertical-align: middle;
    }
    &.th-bottom tbody th,
    &.td-bottom tbody td {
        vertical-align: bottom;
    }
    &.thead-left thead,
    &.t-left tbody,
    &.th-left tbody th,
    &.td-left tbody td {
        text-align: left;
    }
    &.thead-right thead,
    &.t-right tbody,
    &.th-right tbody th,
    &.td-right tbody td {
        text-align: right;
    }
    &.thead-center thead,
    &.t-center tbody,
    &.th-center tbody th,
    &.td-center tbody td {
        text-align: center;
    }
}

.table-row {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid $pale;
    line-height: 1.3;
    @include media(sm) {
        border-collapse: collapse;
    }
    thead {
        background-color: $thin;
    }
    th, td {
        border: 1px solid $pale;
        padding: 20px 10px;
        font-weight: 400;
        @include media(xs) {
            display: block;
            border: 0;
        }
    }
    th {
        @include media(xs) {
            padding: 15px 10px 10px;
        }
    }
    td {
        @include media(xs) {
            padding: 0 10px 15px;
        }
    }
    tr:not(:first-child) th {
        border-top: 1px solid $pale;
    }
}



// list

.list {
    &.sp-md {
        & > li + li {
            margin-top: 20px;
        }
    }
    &.sp-sm {
        & > li + li {
            margin-top: 10px;
        }
    }
}

.list-ori {
  & > li {
    position: relative;
    padding-left: 30px;
    span {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.list-circle {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "●";
      top: 0;
      left: 0;
    }
  }
}
.list-asta {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "＊";
      top: 0;
      left: 0;
    }
  }
}
.list-note {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "※";
      top: 0;
      left: 0;
    }
  }
}
.list-disc {
  & > li {
    position: relative;
    padding-left: 14px;
    &:before {
      position: absolute;
      content: "・";
      top: 0;
      left: 0;
    }
  }
}

.list-number {
    list-style-type: none;
    list-style-position: inside;
    & > li {
        padding-left: 1.5em;
        text-indent: -1.5em;
        counter-increment: cnt;
        text-align: justify;
        &:before {
            display: marker;
            content: "0" counter(cnt) " ";
            margin-right: 3px;
            color: $aux;
        }
        &:nth-child(n+10):before {
            content: "" counter(cnt) " ";
        }
    }
}







/* movie youtube
--------------------------------------------- */
.iframe-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.video-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


/* sef
--------------------------------------------- */
.sef {
    opacity: 0;
    transition: all 1s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.sef.sef-ori {
    opacity: 1;
}

.sef.show {
    opacity: 1;
    transform: none;
}

.sef.sef-cover {
    opacity: 1;
    position: relative;
    overflow: hidden;
    &.show:before {
        animation: sef-cover 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    }
    &:before {
        content: '';
        background: $key;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
@keyframes sef-cover {
  100% {
    transform: translateX(100%);
  }
}

.sef-blur {
    filter: blur(6px);
    &.show {
        filter: none;
    }
}
.sef-left {
transform: translate(-100px, 0);
}

.sef-right {
transform: translate(100px, 0);
}

.sef-up {
transform: translate(0, 100px);
}

.sef-down {
transform: translate(0, -100px);
}

.sef-scaleUp {
transform: scale(.5);
}

.sef-scaleDown {
transform: scale(1.5);
}


/* flexbox
--------------------------------------------- */
.as-center { align-self: center; }
.as-start { align-self: flex-start; }
.as-end { align-self: flex-end; }
.as-stretch { align-self: stretch; }
.as-baseline { align-self: baseline; }
.as-auto { align-self: auto; }


/* カラー
--------------------------------------------- */
.c-white { color: white !important; }
.c-black { color: $black !important; }
.c-aux { color: $aux !important; }
.c-pale { color: $pale !important; }
.c-thin { color: $thin !important; }
.c-main { color: $main !important; }
.c-key { color: $key !important; }
.c-link { color: $link !important; }
.c-em { color: $em !important; }


.bg-white { background-color: white !important; }
.bg-black { background-color: $black !important; }
.bg-aux { background-color: $aux !important; }
.bg-pale { background-color: $pale !important; }
.bg-thin { background-color: $thin !important; }
.bg-key { background-color: $key !important; }
.bg-key-thin { background-color: lighten($key, 30%) !important; }
.bg-gray { background-color: $gray !important; }



/* スペース
--------------------------------------------- */
.mt-skin  { margin-top:   5px !important; }
.mt-min   { margin-top:  10px !important; }
.mt-xs    { margin-top:  20px !important; }
.mt-sm    { margin-top:  30px !important; }
.mt-md    { margin-top:  calc(40px + 20*(100vw - 320px)/1280) !important; }
.mt-lg    { margin-top:  calc(60px + 20*(100vw - 320px)/1280) !important; }
.mt-xl    { margin-top:  calc(80px + 40*(100vw - 320px)/1280) !important; }
.mt-max   { margin-top:  calc(120px + 40*(100vw - 320px)/1280) !important; }

.mr-skin  { margin-right:   5px !important; }
.mr-min   { margin-right:  10px !important; }
.mr-xs    { margin-right:  20px !important; }
.mr-sm    { margin-right:  30px !important; }

.ml-skin  { margin-left:   5px !important; }
.ml-min   { margin-left:  10px !important; }
.ml-xs    { margin-left:  20px !important; }
.ml-sm    { margin-left:  30px !important; }

.mb-skin  { margin-bottom:   5px !important; }
.mb-min   { margin-bottom:  10px !important; }
.mb-xs    { margin-bottom:  20px !important; }
.mb-sm    { margin-bottom:  30px !important; }
.mb-md    { margin-bottom:  calc(40px + 20*(100vw - 320px)/1280) !important; }
.mb-lg    { margin-bottom:  calc(60px + 20*(100vw - 320px)/1280) !important; }
.mb-xl    { margin-bottom:  calc(80px + 40*(100vw - 320px)/1280) !important; }
.mb-max   { margin-bottom:  calc(120px + 40*(100vw - 320px)/1280) !important; }

.pt-skin  { padding-top:   5px !important; }
.pt-min   { padding-top:  10px !important; }
.pt-xs    { padding-top:  20px !important; }
.pt-sm    { padding-top:  30px !important; }

.pr-skin  { padding-right:   5px !important; }
.pr-min   { padding-right:  10px !important; }
.pr-xs    { padding-right:  20px !important; }
.pr-sm    { padding-right:  30px !important; }

.pl-skin  { padding-left:   5px !important; }
.pl-min   { padding-left:  10px !important; }
.pl-xs    { padding-left:  20px !important; }
.pl-sm    { padding-left:  30px !important; }

.pb-skin  { padding-bottom:   5px !important; }
.pb-min   { padding-bottom:  10px !important; }
.pb-xs    { padding-bottom:  20px !important; }
.pb-sm    { padding-bottom:  30px !important; }



// フォント設定
// ----------------------------------------
.wf-active {
    .ff { font-family: $font !important; }
    .ff-min { font-family: $font-min !important; }
    .ff-en { font-family: $font-en !important; }
}
.ff { font-family: $font-load; }
.ff-min { font-family: $font-load; }
.ff-en { font-family: $font-load; }

.fw-b { font-weight: 700 !important; }
.fw-n { font-weight: 400 !important; }

.fs-min { font-size:  72% !important; }
.fs-xs  { font-size:  86% !important; }
.fs-sm   { font-size:  93% !important; }
.fs-md   { font-size: 115% !important; }
.fs-lg  { font-size: 143% !important; }
.fs-xl { font-size: 172% !important; }


.fs10 { font-size: 0.625rem !important; }
.fs11 { font-size: 0.6875rem !important; }
.fs12 { font-size: 0.75rem !important; }
.fs13 { font-size: 0.8125rem !important; }
.fs14 { font-size: 0.875rem !important; }
.fs16 { font-size: 1.0rem !important; }
.fs18 { font-size: 1.125rem !important; }
.fs20 { font-size: 1.25rem !important; }
.fs24 { font-size: 1.5rem !important; }
.fs28 { font-size: 1.75rem !important; }
.fs30 { font-size: 1.875rem !important; }
.fs32 { font-size: 2.0rem !important; }
.fs36 { font-size: 2.25rem !important; }
.fs40 { font-size: 2.5rem !important; }
.fs48 { font-size: 3.0rem !important; }

.lh10 { line-height: 1.0 !important; }
.lh11 { line-height: 1.1 !important; }
.lh12 { line-height: 1.2 !important; }
.lh13 { line-height: 1.3 !important; }
.lh14 { line-height: 1.4 !important; }
.lh15 { line-height: 1.5 !important; }
.lh18 { line-height: 1.8 !important; }
.lh20 { line-height: 2.0 !important; }


// ツール設定
// ----------------------------------------
.ta-l { text-align: left !important;}
.ta-c { text-align: center !important;}
.ta-r { text-align: right !important;}
.ta-j { text-align: justify !important;}

.ta-cl {
    text-align: justify !important;
    @include media(md) {
        text-align: center !important;
    }
}

@include media(xs) {
    .xs-w90c {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
}

.xl-on {
    display: none;
    @include media(xl) {
        display: block;
    }
}
.xl-no {
    @include media(xl) {
        display: none;
    }
}
.lg-on {
    display: none;
    @include media(lg) {
        display: block;
    }
}
.lg-no {
    @include media(lg) {
        display: none;
    }
}
.md-on {
    display: none;
    @include media(md) {
        display: block;
    }
}
.md-no {
    @include media(md) {
        display: none;
    }
}
.sm-on {
    display: none;
    @include media(sm) {
        display: block;
    }
}
.sm-no {
    @include media(sm) {
        display: none;
    }
}
.xs-on {
    display: none;
    @include media(xs) {
        display: block;
    }
}
.xs-no {
    display: block;
    @include media(xs) {
        display: none;
    }
}


.d-block { display: block; }
.d-iblock { display: inline-block; }
.d-table { display: table; }
.d-tablec { display: table-cell; }
.d-inline { display: inline; }


.fl-r {
    @include media(sm) {
        float: right;
    }
}
.fl-l {
    @include media(sm) {
        float: left;
    }
}
figure.fl-r {
    margin: 0 0 20px;
    @include media(sm) {
        margin: 0 0 20px 20px;
    }
}
figure.fl-l {
    margin: 0 0 20px;
    @include media(sm) {
        margin: 0 20px 20px 0;
    }
}






// clearfix
// ----------------------------------------
.clearfix {
    display: block;
    min-height: 1%;
}
.clearfix:after {
    clear: both;
    content:"";
    display: block;
    height: 0;
    visibility: hidden;
}
* html .clearfix {
    height: 1%;
    /*\*//*/
    height: auto;
    overflow: hidden;
    /**/
}
.clear {
    clear: both;
}