@charset 'UTF-8';

// ========================================
// WordPress
// ========================================



.breadcrumb {
    width: 100%;
    display: block;
    color: $black;
    font-size: 0.75rem;
    line-height: 1.2;
    padding: 10px 0 0;
    .home,
    .post,
    .category {
        padding: 0 5px 0 0;
        display: inline-block;
        vertical-align: middle;
        font-size: 0.75rem;
        line-height: 1.2;
        color: $black;
        transition: color .3s ease-in-out;
        &:hover {
            color: $link;
        }
        span.current-item {
            color: $black;
        }
    }
    .current-item {
        color: $black;
    }
}

/* ページャー
--------------------------------------------- */
.sec-info {
    .wp-pagenavi {
        text-align: center;
        line-height: 1;
        a {
            line-height: 1;
            display: inline-block;
            margin: 5px;
            padding: 5px 8px 8px;
            background-color: white;
            transition: background-color .3s, color .3s;
            border-radius: 30px;
            border: none;
            &:hover {
                background-color: $link;
                color: white;
            }
        }
        span {
            line-height: 1;
            display: inline-block;
            margin: 5px;
            padding: 5px 8px 8px;
            color: $aux;
            background-color: white;
            border-radius: 30px;
            border: none;
            &.current {
                background-color: $key;
                color: white;
            }
        }
    }
}


/* オリジナル
--------------------------------------------- */

.sec-form {
/*
    .error_title {
        margin: 40px 0 0;
        text-align: center;
        color: $em;
    }
    .error {
        color: $em;
    }
    .error-list {
        color: $em;
    }
*/
    span.wpcf7-list-item {
        display: block;
        margin: 0;
        &:not(:first-child) {
            margin: 10px 0 0;
        }
        label {
            display: flex;
            flex-flow: row wrap;
            input {
                margin: 0 10px 0 0;
                width: inherit;
            }
        }
    }
    input[type="radio"] {
        display: inline-block;
        width: inherit;
    }
    input[type="button"] {
        display: inline-block;
        padding: 12px 16px;
        background: $main;
        border: 1px solid $main;
        transition: .3s;
        width: 100%;
        max-width: 240px;
        color: white;
        text-align: center;
        font-family: $font;
        &:hover {
            background: white;
            color: $main;
        }
    }
    input[type="submit"] {
        display: inline-block;
        padding: 12px 16px;
        background: $link;
        border: 1px solid $link;
        transition: .3s;
        width: 100%;
        max-width: 240px;
        color: white;
        text-align: center;
        font-family: $font;
        cursor: pointer;
        border-radius: 100px;
        &:hover {
            background: white;
            color: $link;
        }
    }
    button[type="submit"] {
        display: inline-block;
        padding: 16px;
        background: $link;
        border: 1px solid $link;
        border-radius: 100px;
        transition: .3s;
        width: 100%;
        max-width: 240px;
        color: white;
        text-align: center;
        font-family: $font;
        cursor: pointer;
        &:hover {
            background: white;
            color: $link;
        }
    }
    .table-form {
        border-collapse: collapse;
        line-height: 1.3;
        display: block;
        width: 100%;
        border-bottom: 1px solid $pale;
        @include media(md) {
            display: inherit;
        }
        tbody {
            display: block;
            width: 100%;
            @include media(md) {
                display: inherit;
            }
        }
        tr {
            display: block;
            width: 100%;
            border-top: 1px solid $pale;
            @include media(md) {
                display: table;
            }
        }
        th {
            vertical-align: middle;
            position: relative;
            text-align: left;
            display: block;
            width: 100%;
            padding: 20px 0 0 0;
            @include media(md) {
                display: table-cell;
                width: 30%;
                padding: 20px 0;
            }
            div {
                position: relative;
                padding: 0 40px 0 20px;
                border-left: 3px solid $black;
                @include media(md) {
                    border: none;
                    padding: 0 40px 0 0;
                }
            }
            span {
                position: absolute;
                top: 20px;
                right: 0;
                display: block;
                padding: 2px 2px 3px;
                background-color: $em;
                color: white;
                font-size: calc(12px + 2*(100vw - 320px)/1280);
                font-weight: normal;
                line-height: 1;
                @include media(md) {
                    top: 50%;
                    transform: translate(0, -50%);
                }
                &.self {
                    background-color: white;
                    color: $black;
                }
            }
            p {
                font-size: calc(12px + 4*(100vw - 320px)/1280);
                line-height: 1.5;
                margin-top: 10px;
                color: $main;
                a:hover {
                    text-decoration: underline;
                }
            }
        }
        td {
            display: block;
            width: 100%;
            padding: 10px 0 20px 0;
            vertical-align: middle;
            @include media(md) {
                display: table-cell;
                width: 70%;
                padding: 20px 0 20px 40px;
            }
            input,
            textarea,
            select {
                width: 100%;
                border: 1px solid $pale;
                padding: 8px;
                font-family: $font;
                @include media(md) {
                    padding: 16px;
                }
            }
            input {
                &::placeholder {
                    color: $gray;
                    font-family: $font;
                }
            }
            textarea {
                resize: vertical;
            }
            .radio {
                li {
                    line-height: 2;
                }
            }
            p {
                font-size: 0.8125rem;
                line-height: 1.8;
                margin-top: 10px;
                text-align: justify;
                color: $aux;
                & + p {
                    margin-top: 10px;
                }
            }
            span {
                &>span {
                    input {
                        margin: 3px 10px 0 0;
                    }
                }
            }
        }
    }
    .ajax-loader {
        display: block;
    }
}



/* WordPress Native Styles
--------------------------------------------- */
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }

img[class*='wp-image-'],
img[class*='attachment-'] {
    max-width: 100%;
    height: auto;
}

.wp-caption {
    max-width: 96%;
    padding: 8px;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
    max-width: 100%;
}

.wp-caption p.wp-caption-text {
    margin: 0;
    padding: 5px 0;
    font-size: 1.4rem;
}

.postContent h2 {
    font-size: 2.2rem;
}
.postContent h3 {
    font-size: 2.0rem;
}
.postContent h4 {
    font-size: 1.8rem;
}
.postContent h5 {
    font-size: 1.6rem;
}
.postContent h6 {
    font-size: 1.4rem;
}

.postContent p {
    margin-bottom: 10px;
}
.postContent pre {
    background-color: $thin;
    padding: 8px;
    margin: 16px 0;
    font-size: 1.4rem;
}
.postContent strong {
    font-weight: bold;
}
.postContent em {
    font-style: italic;
}
.postContent ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 7px solid #ccc;
}
.postContent hr {
    border-top: 1px solid #ccc;
    margin: 20px 5px;
}
.postContent .aligncenter,
.postContent div.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.postContent .alignleft {
    float: left;
    margin-right:10px;
}
.postContent .alignright {
    float: right;
}
.postContent .wp-caption-text {
    font-size: 1.2rem;
}


