@charset "UTF-8";

// メディアクエリ
@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin clear {
  content: "";
  clear: both;
  display: block;
  overflow: hidden;
}