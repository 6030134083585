/*
Version: 1.0
Author:リシカ
Author URI: https://resika.net/
 */
@charset "UTF-8";

@import "_reset.scss";
@import "_mixin.scss";
@import "_setting.scss";
@import "_wp.scss";
@import "_module.scss";


html.lock,
body.lock {
    overflow: hidden;
}
#container {
    position: relative;
}

// ----------------------------------------
// ヘッダー
// ----------------------------------------
.header {
    z-index: 10000;
    transition: .3s;
    width: 100%;
    padding: 20px 3% 30px;
    background-color: $thin;
}


.gnav {
    background-color: $thin;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    .wf-active & {
        &--logo {
            a {
                span {
                    font-family: $font-min;
                }
            }
        }
    }
    &--menu {
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
        }
    }
    &--list {
        display: none;
        @include media(md) {
            display: flex;
        }
        li {
            font-size: 0.875rem;
            line-height: 1.3;
            &:not(:last-child) {
                margin: 0 20px 0 0;
            }
            a {
                color: $black;
                position: relative;
                font-weight: 400;
                display: block;
                &:hover {
                    &:before {
                        opacity: 0;
                    }
                }
                span {
                    display: block;
                    font-size: .5rem;
                    line-height: 1.0;
                    font-family: $font-load;
                    margin: 5px 0 0;
                    white-space: nowrap;
                }
            }
        }
    }
    &--logo {
        line-height: 1.0;
        &:hover {
            img {
                opacity: 1;
            }
        }
        img {
            height: auto;
            width: 210px;
            @include media(sm) {
                width: 340px;
            }
            @include media(md) {
                width: inherit;
            }
        }
    }
    &--burger {
        margin: 0 0 0 40px;
        background-color: white;
        border-radius: 200px;
        padding: 20px;
        line-height: 0;
        cursor: pointer;
        transition: top .3s $easeOutCubic, right .3s $easeOutCubic;
        .is-animation & {
            z-index: 99;
            position: fixed;
            top: 20px;
            right: 20px;
        }
        &:hover{
            .icon {
                div {
                    &:nth-of-type(2) {
                        width: 100%;
                    }
                    &:nth-of-type(3) {
                        width: 100%;
                    }
                }
            }
        }
        .icon {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            div {
                position: absolute;
                display: inline-block;
                transition: width .3s $easeOutCubic;
                right: 0;
                height: 1px;
                background-color: $black;
                &:nth-of-type(1) {
                    width: 100%;
                    top: 0;
                }
                &:nth-of-type(2) {
                    width: 70%;
                    top: 50%;
                }
                &:nth-of-type(3) {
                    width: 40%;
                    bottom: 0;
                }
            }
        }
    }
}






// ----------------------------------------
// メイン
// ----------------------------------------
.main {
    display: block;
    background-color: $thin;
    padding: 0 3% calc(60px + 60*(100vw - 320px)/1280);
}

.sec-hero {
    position: relative;
    img {
        width: 100%;
        height: auto;
        z-index: 0;
    }
    .box {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.wf-active {
    .sec-front {
        .title {
            font-family: $font-min;
            span {
                font-family: $font-en;
            }
        }
    }
}
.sec-front {
    .title {
        font-family: $font-load;
        font-size: calc(20px + 10*(100vw - 320px)/1280);
        line-height: 1.8;
        font-weight: 700;
        @include media(xs) {
            line-height: 1.6;
        }
        span {
            font-family: $font-load;
            display: block;
            font-size: calc(40px + 40*(100vw - 320px)/1280);
            line-height: 1.1;
            color: $key;
            margin: 0 0 20px;
        }
    }
}
.sec-front-lesson,
.sec-front-staff,
.sec-front-info {
    figure {
        z-index: 0;
    }
    .inner {
        margin-top: -20px;
        z-index: 10;
        @include media(md) {
            margin-top: -40px;
        }
    }
}
.sec-access {
    .iframe-wrap {
        z-index: 0;
        padding-top: 396px;
        iframe {
            z-index: 0;
        }
    }
}









.wf-active {
    .page-title {
        .title {
            span {
                font-family: $font-en;
            }
        }
    }
}
.page-title {
    position: relative;
    width: 100%;
    height: auto;
    background: center center / cover no-repeat;
    opacity: 0;
    transition: opacity 2s ease-in;
    &:before {
        display: block;
        padding-top: 180px;
        content: '';
        @include media(md) {
            padding-top: 396px;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: 0;
    }
    .action & {
        opacity: 1;
        .title {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }
    .title {
        position: absolute;
        font-size: calc(16px + 4*(100vw - 320px)/1280);
        font-weight: 700;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        line-height: 1.2;
        color: white;
        opacity: 0;
        transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
        text-align: center;
        z-index: 10;
        span {
            display: block;
            font-family: $font-load;
            font-size: calc(30px + 30*(100vw - 320px)/1280);
            text-align: center;
            color: white;
            margin-bottom: 10px;
        }
    }
}
.page-body {
    margin: calc(40px + 20*(100vw - 320px)/1280) 0 0;
}

.wf-active {
    .info-list {
        span {
            font-family: $font-en;
        }
        a {
            font-family: $font-min;
        }
    }
}
.info-list {
    li {
        &:not(:first-child) {
            margin: 20px 0 0;
        }
        width: 100%;
        span {
            font-family: $font-load;
            display: block;
            font-size: .875rem;
            color: $aux;
            line-height: 1;
        }
        a {
            font-family: $font-load;
            display: block;
            padding: 10px 20px 20px 0;
            position: relative;
            line-height: 1.3;
            color: $black;
            font-size: calc(16px + 8*(100vw - 320px)/1280);
            &:hover {
                color: $link;
                &:before {
                    width: 100%;
                    background-color: $link;
                }
                &:after {
                    border-color: $link;
                    right: 10px;
                }
            }
            &:before {
                position: absolute;
                display: block;
                content: '';
                width: 90%;
                height: 1px;
                left: 0;
                bottom: 0;
                transition: background-color .3s, width .3s;
                background-color: $black;
            }
            &:after {
                position: absolute;
                display: block;
                width: 6px;
                height: 6px;
                top: 50%;
                right: 10%;
                transform: rotate(45deg) translate(-6px, 0);
                border-top: 1px solid $black;
                border-right: 1px solid $black;
                line-height: 1.0;
                content: '';
                transition: border-color .3s, right .3s;
            }
        }
    }
}
.sec-staff {
    dl {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        dt {
            width: 30%;
        }
        dd {
            width: 70%;
        }
    }
}

.lesson-table {
    width: 100%;
    border-collapse: collapse;
    &.personal {
        td {
            width: 35%;
        }
    }
    tr {
        width: 100%;
        border-bottom: 1px solid $black;
    }
    th {
        width: 30%;
        font-weight: 400;
        padding: 10px;
        text-align: left;
        line-height: 1.5;
    }
    td {
        width: 70%;
        text-align: right;
        padding: 10px;
        line-height: 1.5;
    }
}

.timetable-table {
    width: 960px;
    border-collapse: collapse;
    thead {
        th,td {
            background-color: $main;
            color: white;
        }
    }
    tr {
        width: 100%;
    }
    th {
        width: 16%;
        font-weight: 400;
        padding: 10px;
        text-align: center;
        line-height: 1.5;
        border: 1px solid $pale;
        background-color: white;
    }
    td {
        width: 14%;
        text-align: center;
        padding: 10px;
        line-height: 1.5;
        border: 1px solid $pale;
        background-color: white;
    }
}






.wf-active {
    .post-title .title,
    .sec-postmeta .title {
        font-family: $font-min;
    }
    .sec-postmeta {
        .date {
            font-family: $font-en;
        }
    }
}
.post-title {
    width: 100%;
    .action & {
        opacity: 1;
        .title {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
    .title {
        font-size: calc(20px + 10*(100vw - 320px)/1280);
        font-weight: 700;
        transform: translate(0, 40px);
        line-height: 1.2;
        color: $black;
        opacity: 0;
        transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
        text-align: center;
        font-family: $font-load;
        border-bottom: 1px solid $aux;
        padding: 0 0 20px;
    }
}
.post-body {
    margin: calc(40px + 20*(100vw - 320px)/1280) 0 0;
}
.sec-postmeta {
    border-bottom: 1px solid $thin;
    padding: 0 0 20px;
    margin: 0 0 40px;
    .title {
        font-size: calc(20px + 10*(100vw - 320px)/1280);
        font-weight: 700;
        font-family: $font-load;
        text-align: center;
        margin: 0 0 10px;
    }
    .date {
        font-family: $font-load;
        display: block;
        margin: 0 0 5px;
        line-height: 1.0;
        font-size: 1.25rem;
    }
    .category {
        line-height: 1.0;
    }
    .cate {
        display: inline-block;
        line-height: 1.0;
        margin: 5px 5px 0;
        background-color: $thin;
        border-radius: 30px;
        padding: 5px 10px;
        font-size: 0.875rem;
    }
    .wp-block-image + p, p + .wp-block-image {
        margin-top: calc(30px + 30*(100vw - 320px)/1280);
    }
}
.sec-postbody {
    .inner {
        background-color: white;
        padding: calc(20px + 40*(100vw - 320px)/1280) calc(20px + 60*(100vw - 320px)/1280) calc(20px + 60*(100vw - 320px)/1280);
        border-radius: calc(10px + 20*(100vw - 320px)/1280);
    }
}





















// ----------------------------------------
// フッター
// ----------------------------------------

.footer {
    padding: 40px 0 120px;
    background-color: $main;
    .copyright {
        text-align: center;
        font-size: 0.75rem;
        color: white;
    }
}

// ----------------------------------------
// 特殊
// ----------------------------------------


// grobal nav
.is-animation #gnav {
    display: inline-block;
}


// overlay
.modal_overlay {
    z-index: 97;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(147, 0, 81, 0.8);
}
.modal_wrap {
    z-index: 100;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.modal_content {
    display: none;
    width: 100%;
    height: 100%;
    position: relative;
}




// drawer
.drawermenu {
    position: relative;
    overflow: auto;
    background-color: rgba(147, 0, 81, 0.9);
    transition: opacity .3s ease-out;
    padding: 30px calc(20px + 20*(100vw - 320px)/1280) 30px calc(20px + 20*(100vw - 320px)/1280);
    .wf-active & {
        &--list {
            a {
                span {
                    font-family: $font-en;
                }
            }
        }
    }
    &--burger {
        cursor: pointer;
        position: relative;
        display: block;
        margin: 0 0 0 auto;
        width: calc(60px + 60*(100vw - 320px)/1280);
        height: calc(60px + 60*(100vw - 320px)/1280);
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            right: 0;
            width: 100%;
            height: 2px;
            background-color: $key;
            &:nth-of-type(1) {
                top: 50%;
                transform: rotate(45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar01 .75s forwards;
                animation: drawer-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                transform: rotate(-45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar03 .75s forwards;
                animation: drawer-bar03 .75s forwards;
            }
        }
    }
    &--section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: left;
    }
    &--list {
        line-height: 1.0;
        li {
            line-height: 1.0;
            &:not(:first-child){
                margin-top: 20px;
            }
        }
        a {
            color: $key;
            font-size: calc(14px + 2*(100vw - 320px)/1280);
            transition: color .3s ease-in-out;
            line-height: 1.0;
            span {
                font-size: calc(40px + 30*(100vw - 320px)/1280);
                display: inline-block;
                margin: 0 10px 0 0;
                font-family: $font-load;
                line-height: 1.0;
            }
            &:hover {
                color: white;
            }
        }
    }
}
@-webkit-keyframes drawer-bar01 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(45deg); }
}
@keyframes drawer-bar01 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(45deg); }
}
@-webkit-keyframes drawer-bar03 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(-45deg); }
}
@keyframes drawer-bar03 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(-45deg); }
}





.page-cover {
    display: none;
    &.is-active {
        display: block;
        z-index: 1900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
}



/*
.loadingEffect {
    display: block;
    z-index: 100000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loadingEffect::before, .loadingEffect::after {
    display: block;
    z-index: 99;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #F5F5F5;
    line-height: 1;
    content: ' ';
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    transition-delay: .3s;
}

.loadingEffect::before {
    top: 0;
}

.loadingEffect::after {
    bottom: 0;
}

.loaded .loadingEffect::before {
    height: 0;
    transform: translateY(-1%);
}

.loaded .loadingEffect::after {
    height: 0;
    transform: translateY(1%);
}
*/


